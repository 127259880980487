<template>
  <div
    class="program"
    :style="{ height: clientHeight - 180 + 'px' }"
    style="overflow-y: scroll"
  >
    <!-- 列表 -->
    <div class="programlist">
      <!-- table -->
      <div class="tables">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column prop="deviceId" label="设备编号"></el-table-column>
          <el-table-column
            prop="companyName"
            label="企业名称"
          ></el-table-column>
          <!-- <el-table-column
            prop="crdCompanyId"
            label="所属企业"
          ></el-table-column> -->
          <el-table-column prop="pushUrl" label="推送地址"></el-table-column>
          <!-- <el-table-column prop="time" label="播放时长" width="120px"></el-table-column> -->
          <el-table-column prop="system" label="系统操作">
            <template slot-scope="scope">
              <img
                class="buts"
                src="../../assets/images/for.png"
                @click="handleEdit(scope.$index, scope.row)"
                alt
              />
              <img
                class="buts bu"
                src="../../assets/images/del.png"
                @click="handleDelete(scope.$index, scope.row)"
                alt
              />
              <el-button
                class="details"
                id="testText"
                size="mini"
                v-if="scope.row.type == '0'"
                @click="testBtn(scope.$index, scope.row)"
                >测试</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="bottom">
        <div class="bottom-left">
          <div>
            <el-button class="bottomTwo" @click="add">添加</el-button>
          </div>
        </div>
        <div class="bottom-right">
          <div class="bottom-fen">
            <!-- 分页 -->
            <div class="pages">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :page-size="pageSize"
                :current-page="pageCount"
                layout="total, prev, pager, next"
                :total="totalCount"
              >
              </el-pagination>
              <!-- <el-pagination layout="prev, pager, next" :total="50">
              </el-pagination> -->
            </div>
          </div>
          <div class="buttons">
            <el-button>数据导出</el-button>
            <el-button>数据分析</el-button>
          </div>
        </div>
      </div>
    </div>
    <!-- 增加按钮的弹框 -->
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="40%"
      :before-close="handleClose"
    >
      <el-form
        :model="formInline"
        class="demo-form-inline"
        style="margin-left: 20px"
        id="dialogA"
        label-width="auto"
      >
        <el-form-item label="设备编号">
          <el-input
            v-model="formInline.deviceId"
            placeholder=""
            style="width: 50%"
          ></el-input>
        </el-form-item>
        <el-form-item label="是否康尔欧德系统" class="radioCss">
          <el-select
            v-model="formInline.type"
            clearable
            style="width: 33%"
            placeholder="请选择"
            @input="$forceUpdate()"
            @change="$forceUpdate()"
          >
            <el-option
              v-for="item in typeSelect"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属企业">
          <el-input
            v-model="formInline.companyName"
            placeholder=""
            style="width: 50%"
            v-if="this.formInline.type == '0'"
          ></el-input>
          <el-select
            v-model="formInline.crdCompanyId"
            v-if="this.formInline.type == '1'"
            clearable
            style="width: 50%"
            placeholder="请选择"
          >
            <el-option
              v-for="item in crdCompanyIds"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="推送地址">
          <el-input
            v-model="formInline.pushUrl"
            placeholder=""
            style="width: 50%"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addList">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 修改按钮的弹框 -->
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible1"
      width="40%"
      :before-close="handleClose1"
    >
      <el-form
        :model="formInlines"
        class="demo-form-inline"
        style="margin-left: 20px"
        id="dialogA"
        label-width="auto"
      >
        <el-form-item label="设备编号">
          <el-input
            v-model="formInlines.deviceId"
            placeholder=""
            style="width: 50%"
          ></el-input>
        </el-form-item>
        <el-form-item label="是否康尔欧德系统" class="radioCss">
          <el-select
            v-model="formInlines.type"
            clearable
            style="width: 33%"
            placeholder="请选择"
            @input="$forceUpdate()"
            @change="$forceUpdate()"
          >
            <el-option
              v-for="item in typeSelect"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
          <!-- <el-radio-group v-model="formInlines.type">
            <el-radio label="0">否</el-radio>
            <el-radio label="1">是</el-radio>
          </el-radio-group> -->
        </el-form-item>

        <el-form-item label="所属企业">
          <el-input
            v-model="formInlines.companyName"
            placeholder=""
            style="width: 50%"
            v-if="this.formInlines.type == '0'"
          ></el-input>
          <el-select
            v-model="formInlines.crdCompanyId"
            v-if="this.formInlines.type == '1'"
            clearable
            style="width: 50%"
            placeholder="请选择"
          >
            <el-option
              v-for="item in crdCompanyIds"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="推送地址">
          <el-input
            v-model="formInlines.pushUrl"
            placeholder=""
            style="width: 50%"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible1 = false">取 消</el-button>
        <el-button type="primary" @click="upList">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { apiShout } from "../../api1/configShout";
// import { api } from "../../api1/config";
export default {
  data() {
    return {
      clientHeight: document.body.clientHeight,
      dialogVisible: false,
      dialogVisible1: false,
      //分页
      pageSize: 10,
      totalCount: 100,
      pageCount: 1,
      tableData: [],
      formInline: {
        deviceId: "",
        crdCompanyId: "",
        pushUrl: "",
        type: "",
        companyName: "",
      },
      formInlines: {
        deviceId: "",
        crdCompanyId: "",
        pushUrl: "",
        type: "",
        companyName: "",
      },
      crdCompanyIds: [],
      typeSelect: [
        {
          id: "0",
          name: "否",
        },
        {
          id: "1",
          name: "是",
        },
      ],
      screenWidth: "",
    };
  },
  created() {
    this.getList();
    this.getCrdCompanys();
  },
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenHeight = document.body.clientHeight;
        that.clientHeight = window.screenHeight;
      })();
    };
  },
  watch: {
    clientHeight(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.clientHeight = val;
        this.timer = true;
        let that = this;
        setTimeout(function () {
          // 打印screenWidth变化的值
          console.log(that.clientHeight);
          that.timer = false;
        }, 400);
      }
    },
  },
  methods: {
    //测试用例
    testBtn(index, row) {
      console.log(index, row, "查看测试用例拿到的数据");
      this.$confirm("发送测试用例, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        apiShout.getTestCase({ id: row.id }).then((res) => {
          if (res.data.code == 200) {
            this.$message({
              type: "success",
              message: "发送成功!",
            });
          } else {
            this.$message.error(res.data.msg);
          }
        });
      });
    },
    getList() {
      apiShout
        .crdmediGetList({
          pageSize: this.pageSize,
          pageCount: this.pageCount,
        })
        .then((res) => {
          console.log("列表：", res.data);
          if (res.data.code == 200) {
            this.tableData = res.data.result.data;
            this.totalCount = res.data.result.page.totalCount;
            this.pageSize = res.data.result.page.pageSize;
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    //获取企业
    getCrdCompanys() {
      apiShout.getCrdCompany().then((res) => {
        if (res.data.code == 200) {
          console.log("所属企业", res.data);
          this.crdCompanyIds = res.data.result;
          this.tableData.forEach((element) => {
            this.crdCompanyIds.forEach((elements) => {
              if (element.crdCompanyId === elements.id) {
                console.log(elements.name, "查看所属企业名称");
              }
            });
          });
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    add() {
      this.dialogVisible = true;
      this.formInline = {};
      this.formInline.type = this.typeSelect[0].id;
    },
    addList() {
      apiShout
        .crdmediAddInfo({
          crdCompanyId: this.formInline.crdCompanyId,
          deviceId: this.formInline.deviceId,
          pushUrl: this.formInline.pushUrl,
          type: this.formInline.type,
          companyName: this.formInline.companyName,
        })
        .then((res) => {
          if (res.data.code == 200) {
            console.log(res.data.result, "查看是否成功");
            this.$message({
              message: "成功添加",
              type: "success",
            });
            this.getList();
            this.dialogVisible = false;
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    //导出
    exportList() {},
    //分页
    handleSizeChange(size) {
      //console.log("size:", size);
      this.pageSize = size;
      this.getList();
    },
    //当前在第几页
    handleCurrentChange(pageCount) {
      //console.log("pageCount:", pageCount);
      this.pageCount = pageCount;
      this.getList();
    },
    handleEdit(index, row) {
      this.formInlines = {};
      console.log(index, row, "查询row");
      this.dialogVisible1 = true;
      this.formInlines = JSON.parse(JSON.stringify(row));
    },
    upList() {
      apiShout
        .crdmediUpdateInfo({
          id: this.formInlines.id,
          crdCompanyId: this.formInlines.crdCompanyId,
          deviceId: this.formInlines.deviceId,
          pushUrl: this.formInlines.pushUrl,
          type: this.formInlines.type,
          companyName: this.formInlines.companyName,
        })
        .then((res) => {
          if (res.data.code == 200) {
            console.log(res.data.result, "查看是否成功");
            this.$message({
              message: "成功修改",
              type: "success",
            });
            this.getList();
            this.dialogVisible1 = false;
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    handleDelete(index, row) {
      console.log(index, row, "查询row信息");
      this.$confirm("是否删除此条数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      }).then(() => {
        apiShout.crdmediDeleteInfo({ id: row.id }).then((res) => {
          console.log(res, "删除成功");
          // window.location.reload();
          this.$message({
            message: "成功删除",
            type: "success",
          });
          this.getList();
        });
      });
    },
    handleClose() {
      this.dialogVisible = false;
    },
    handleClose1() {
      this.dialogVisible1 = false;
      this.getList();
    },
  },
  beforeDestroy() {},
};
</script>

<style lang='less' scoped>
.dialogA /deep/ .el-form-item__content {
  margin-left: 0px !important;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
#dialogA /deep/ .el-form-item__content {
  margin-left: 0px !important;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
#dialogA /deep/.el-form-item__label-wrap {
  margin-left: 0px !important;
}
.bottomContent {
  margin-left: 25px;
  margin-bottom: 15px;
  font-size: 16px;
  color: #606266;
}
.bottomTwo {
  background: #d78673;
  margin-left: 30px;
  color: #ffffff;
  padding: 8px 22px;
  margin-top: 15px;
  border-radius: 20px;
}
/deep/.el-form-item {
  margin-right: 20px !important;
}
/deep/.el-input__inner {
  height: 33px;
  line-height: 33px;
}
/deep/.el-input__icon {
  line-height: 33px;
  width: auto;
  height: auto;
}
/deep/.el-table th.el-table__cell > .cell {
  font-size: 14px;
  color: #909399;
}
/deep/.el-table td.el-table__cell div {
  color: #606266;
  font-size: 14px;
}
/deep/.el-table .el-table__cell {
  text-align: center;
}
/deep/.el-pager {
  margin-top: 5px;
}
.program {
  position: absolute;
  height: 100%;
  width: 100%;
  // 列表
  .programlist {
    margin-left: 25px;
    margin-right: 40px;
    // 上
    .protop {
      // position: relative;
      margin-top: 25px;
      height: auto;
      margin-right: 40px;
      display: flex;
      align-items: center;
      span {
        margin-right: 10px;
        color: #909399;
        font-size: 14px;
        font-weight: bold;
      }
      // input框
      .el-input__inner {
        border-radius: 0;
        border: 1px solid #e1e1e1;
      }
      // 播放次数
      .play {
        position: absolute;
        left: 300px;
        top: 3px;
        .el-input {
          width: 134px;
        }
      }
      // 关键词
      .antistop {
        // position: absolute;
        left: 700px;
        top: 3px;
        display: flex;
        align-items: center;
        .spanguan {
        }
        .el-input {
          width: 230px;
        }
        .buttons {
          margin-left: 14px;
        }
      }
    }
    // table
    .tables {
      .el-table::before {
        height: 0;
      }
      .el-table {
        td,
        th.is-leaf {
          border: none !important;
        }
        tr th {
          font-size: 19px;
          color: #a8a8a8;
          text-align: center;
        }
        tr td {
          text-align: center;
          font-size: 17px;
          color: #a8a8a8;
        }
      }
      .buts {
        width: 36px;
        height: 27px;
      }
      .bu {
        margin-left: 20px;
      }
    }
    /deep/ .el-table .el-table__cell {
      padding: 12px 0;
      min-width: 0;
      box-sizing: border-box;
      text-overflow: ellipsis;
      vertical-align: middle;
      position: relative;
      text-align: center;
    }
    /deep/ .el-table td.el-table__cell,
    .el-table th.el-table__cell.is-leaf {
      border-bottom: none;
    }
    /deep/ th.el-table__cell.is-leaf {
      border-bottom: none;
    }
    /deep/ .el-table td,
    .el-table th.is-leaf {
      border-bottom: none;
    }
    /deep/ .el-table td,
    .el-table th.is-leaf {
      border-bottom: none !important;
    }
    // 总结
    .total {
      display: flex;
      justify-content: space-between;
      margin-right: 0px;
      margin-top: 30px;
      margin-left: 34px;
      p {
        font-size: 15px;
        display: inline-block;
        margin-right: 36px;
        color: #606266;
      }
      .spanto {
        color: #92aca7;
        margin-left: 17px;
        margin-right: 12px;
      }
    }
    // 按钮
    // .buttons {
    //   display: flex;
    //   // justify-content: flex-end;
    //   margin-left: 40px;
    //   .el-button {
    //     float: right;
    //     width: 130px;
    //     height: 40px;
    //     line-height: 40px;
    //     border-radius: 25px;
    //     margin-top: 20px;
    //     background: #d78673;
    //     color: #ffffff;
    //     font-size: 16px;
    //     padding: 0px;
    //   }
    // }
  }
}
/deep/.el-pager li {
  padding: 0 4px;
  font-size: 13px;
  min-width: 35.5px;
  height: 28px;
  line-height: 15px;
  box-sizing: border-box;
  text-align: center;
}
.bottom {
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
}
.bottom-right {
  float: right;
  margin-right: 20px;
}
.bottom-left {
  position: relative;
  //   display: flex;
}
// 按钮
.buttons {
  display: flex;
  justify-content: flex-end;
  // margin-right: 110px;
  .el-button {
    border-radius: 25px;
    margin-top: 20px;
    background: #d78673;
    color: #ffffff;
    font-size: 16px;
    padding: 12px 33px;
  }
}
/deep/.el-table td,
.el-table th {
  padding: 12px 0;
  min-width: 0;
  box-sizing: border-box;
  text-overflow: ellipsis;
  vertical-align: middle;
  position: relative;
  text-align: center !important;
}
/deep/.el-table .cell {
  text-align: center !important;
  line-height: inherit;
}
/deep/.el-table td,
.el-table th.is-leaf {
  border-bottom: none !important;
}
/deep/.el-table thead tr th.is-leaf {
  border: none;
  border-right: none;
}
/deep/ .el-form-item__label {
  color: #909399;
}
#testText {
  margin-left: 20px;
}
.radioCss /deep/.el-form-item__content {
  display: initial !important;
}
.radioCss /deep/.el-radio-group {
  display: table-header-group;
}
</style>